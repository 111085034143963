<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title fs25">ACL Permissions</div>
        </div>
        <div class="card-body" v-loading="state.loading">

          <!--list-->
          <ItemTable
            :data="state.permissions"
            :columns="state.columnMap"
            :show-actions="false"
          >
          </ItemTable>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue';
import ItemTable from '@/components/Util/ItemTable';
import ButtonComp from '@/components/Util/ButtonComp';
import { useStore } from 'vuex';
import UserAction from '@/components/AccessControl/UserAction';

export default {
  name: 'Permissions',
  components: { UserAction, ItemTable, ButtonComp },
  setup () {

    const store = useStore();
    const permissionStore = store.state.acl.permissions;

    const state = reactive({
      loading: false,

      permissions: [],
      columnMap: [
        { field: 'subject', header: 'Subject' },
        { field: 'name', header: 'Permission' },
        { field: 'description', header: 'Description' },
      ]
    });

    watch(permissionStore, (nv) => {
      state.permissions = nv.data?.permissions || [];
    });

    onMounted(async () => {
      state.loading = true;

      await store.dispatch('acl/fetchPermissions');

      state.loading = false;
    });

    return {
      state
    };
  }
};
</script>
